import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';


const QRCodeGenerator = ({ transactionId }) => {
 
  const qrValue = `${transactionId}`; 

  return (
    <div>
      <QRCodeCanvas value={qrValue} size={256} style={{
        padding: "10px",
        border: "1px solid #969696",
        borderRadius: "20px",
        backgroundColor: "#fff",
      }}/>
    </div>
  );
};

export default QRCodeGenerator;
