import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { base_url, aws } from "../../../environment/environment.tsx";
import './home.css';
import QRCodeGenerator from '../qr/qr.tsx';
import logo from '../../assets/onepay_logo.png'
import whatsapp from '../../assets/whatsapp.png'
import star from '../../assets/star.png'
import dawnload from '../../assets/download.png'

const Home = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Loading state

    const handleConvert = async () => {
        setLoading(true); // Set loading to true when API call starts
        try {
            const response = await axios.post(`${base_url}oneticket/user/event/ticket/qr-view/`, {
                id: id,
            });
            if (response?.data?.status === 100) {
                console.log(response.data)
                setData(response.data.data);
                setError(null);
            } else if (response?.data?.status === 101 || response?.data?.status === 102) {
                setError(response.data.message);
            } else {
                setError(response.data.message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "An unknown error occurred";
            setError(errorMessage);
        } finally {
            setLoading(false); // Set loading to false when API call completes
        }
    };

    useEffect(() => {
        handleConvert();
    }, []);

    const formatDate = (dateString: string) => {
        if (!dateString) return 'Invalid date';

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(date);
    };
    const tickets = data?.tickets || [];

    const totalTicketCount = tickets.reduce((total, ticket) => total + ticket.count, 0);

    return (
        <>
            {loading ? ( // Show loading spinner or message while loading
                <div className="loading-container">
                    <div className="spinner"></div> {/* CSS Spinner */}
                    <p>Loading...</p>
                </div>
            ) : error ? (
                <div className="error-container">
                    <p className="error-message text-danger">{error}</p>
                </div>
            ) : (
                <>
                    <section className='sec_3'>
                        <div className='home_main container'>
                            <div className='row align-items-center'>
                                <div className='col-sm-12 col-md-6 col-lg-6 sec_3_right'>
                                    <div className="banner-wrapper mb-1">
                                        <div
                                            className="banner"
                                            style={{
                                                backgroundImage: `url(${aws}${data?.event_banner})`,
                                                backgroundPosition: '50%',
                                                backgroundSize: 'cover',
                                                height: '100%',
                                                width: '100%'

                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <div className='mt-3 mb-3'>
                                        <div>
                                            <img
                                                src={logo}
                                                alt="Event Banner"
                                                className="banner-image"
                                            />
                                        </div>
                                        <p className='head_text mt-2 mb-3'>Produce This QR Code
                                            At The Entrance</p>

                                        <QRCodeGenerator transactionId={`${data?.event_details}.${data?.transaction_id}`} />
                                        <div className="row mt-2">
                                            <div className="col-12 d-flex justify-content-center align-items-center" style={{ display: "inline-flex" }}>
                                                <p className="qr_p2">TICKET ID</p>
                                                <p className="qr_p2"> : {data?.transaction_id}</p>
                                            </div>
                                        </div>


                                        <p className='qr_p5 mt-2'>{data?.customer_first_name} {data?.customer_last_name}</p>
                                        <p className='qr_p6'>Booking Date: {formatDate(data?.request_datetime)}</p>
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-center align-items-center'>
                                                <div className='border_line'></div>
                                            </div>
                                        </div>
                                        <p className='qr_p7 mt-2'>{totalTicketCount} Tickets - LKR {data?.total_amount ? data.total_amount : 'N/A'}</p>
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-center align-items-center'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data?.tickets?.map((ticket, index) => (
                                                            <tr key={index}>
                                                                <td className="text-left">{ticket.count} {ticket.ticket_name}</td>
                                                                <td className="text-right">LKR {ticket.ticket_amount}</td>
                                                            </tr>
                                                        ))}
                                                        
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-2 button_row">
                                        <div className="col-sm-12 col-md-4 col-lg-4 mb-2" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                <button className="custom-button download_btn" onClick={() => window.open('https://app.oneid.ink/', '_blank')}>
                                                    <img src={dawnload} alt="icon" className="button-icon" />
                                                    <span className="button-text">Download Receipt</span>
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-4 col-lg-4 mb-2" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                <button className="custom-button" onClick={() => window.open('https://www.whatsapp.com/channel/0029VaZFgan7YSczRoYSF90y', '_blank')}>
                                                    <img src={whatsapp} alt="icon" className="button-icon" />
                                                    <span className="button-text">Join Our Community</span>
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-4 col-lg-4 mb-2" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                <button className="custom-button" onClick={() => window.open('https://www.trustpilot.com/evaluate/oneticket.lk', '_blank')}>
                                                    <img src={star} alt="icon" className="button-icon" />
                                                    <span className="button-text">Leave Us Review</span>
                                                </button>
                                            </div>
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div style={{ width: '100%' }} className='mt-auto text-center footer_org'>
                        <p className='footer_t1 mt-3'>BY PURCHASING THIS TICKET, I AGREE TO THE "TERMS AND CONDITIONS"</p>
                        <p className='footer_t2'>ALL RIGHTS RESERVED - ONETICKET EVENT {new Date().getFullYear()}</p>
                        <hr className='hr_padding' />
                        <p className='text-center copy-right mt-2'>© {new Date().getFullYear()} Copyright by Spemai. All rights reserved.</p>
                    </div>
                </>
            )}
        </>
    );
};

export default Home;
